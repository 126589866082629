
import React, { useContext } from "react";


import "./../../styles/desktop-home.css";
import { observer } from "mobx-react";

import { history } from '../../index';
import { RootStoreContext } from "../../store/rootStore";
import DesktopFooter from "../component/footer";
import CommonStore from "../../store/commonStore";



const Purimhome14: React.FC = () => {

        const rootStore = useContext(RootStoreContext);
        function changeBackground(e: any) {
                e.target.style.background = "orange";
        }

        function change2Background(e: any) {
                e.target.style.background = "orangeRed";
        }


        return (
                <div>
                        <div className="web13661">        <div className="group171">


                                <div className="group170">                <div className="group166">                    <div className="group151">                        <div className="services">                            <div className="group163">                                <div className="image1"></div>
                                        <div className="group169">                                    <div className="servicesTitle">{rootStore.commonStore.services}</div>
                                                <div className="wedeliver">{rootStore.commonStore.weDeliver}</div>
                                                <div className="bookAService">


                                                        <button className="bookAMeeting"
                                                                onMouseLeave={change2Background}
                                                                onMouseOver={changeBackground}
                                                                type="submit" onClick={() => {
                                                                        history.push("/contactus");
                                                                        rootStore.commonStore.purpose = "Book A Meeting"
                                                                }}>{rootStore.commonStore.bookaMeeting_button}</button>


                                                </div>
                                        </div>
                                </div>
                                </div>
                                        {/* <div className="ourClients">{rootStore.commonStore.ourClients}</div>
                                        <div className="allprogramlogos"></div>
                                        <div className="ourClientLogos">                            
                                        <div className="group162">                                
                                        <div className="wv"></div>
                                                <div className="cmct"></div>
                                                <div className="cit"></div>
                                        </div>
                                        </div> */}
                                        <div className="turnyourvision">                            <div className="image4"></div>
                                                <div className="digitalVisionContent">                                <div className="digitalVisionContentText">                                    <div className="turnYourdigitalVisionIntoReality">{rootStore.commonStore.turnyour}</div>
                                                        <div className="weBelieveThatTechnologyShouldSupportAndEnhanceYourOrganizationsSuccessOurWideRangeOfBestInClassServicesProvidesCustomizedSolutionsThatFitUniqueItNeeds">{rootStore.commonStore.weBelive}</div>
                                                        <div className="shareYourVisionAndWeWillShareAProposalOnHowToMakeAReality">{rootStore.commonStore.shareyour}</div>
                                                </div>
                                                        <button className="contactButtonscroll"

                                                                onMouseLeave={change2Background}
                                                                onMouseOver={changeBackground}
                                                                type="submit" onClick={() => window.scrollTo({ left: 0, top: 3400, behavior: "smooth" })}>{rootStore.commonStore.contactUs_button}</button>
                                                </div>
                                        </div>
                                </div>
                                </div>
                                        <div className="group168">                    <div className="group164">                        <div className="group172">                            <div className="group156">                                <div className="group155">                                    <div className="group154">                                        <div className="group153">                                            <div className="group157">                                                <div className="image2"></div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                                <div className="image5"></div>
                                        </div>
                                        </div>
                                                <div className="group165">                        <div className="digitalMarketing04037bf1">                            <div className="seoImage"></div>
                                                </div>
                                                        <div className="mobileWeb">                            <div className="mobileWebAppDevelopment86f83456">                                <div className="maskGroup4"></div>
                                                                <div className="image374993b74"></div>
                                                                <div className="group159">                                    <div className="mobileWebAppDevelopment">{rootStore.commonStore.mobileandweb}</div>
                                                                        <div className="builpowerful">{rootStore.commonStore.buildPowerful}</div>
                                                                        <div className="mainBtn">
                                                                                <button className="letsDevelopAnApp"
                                                                                        onMouseLeave={change2Background}
                                                                                        onMouseOver={changeBackground}
                                                                                        type="submit" onClick={() => {
                                                                                                history.push("/contactus");

                                                                                                rootStore.commonStore.purpose = "Mobile/Web app Development"
                                                                                        }}>{rootStore.commonStore.letsDevelop_button}</button>


                                                                        </div>
                                                                </div>
                                                        </div>
                                                                <div className="mobileWebImage"></div>
                                                        </div>
                                                        <div className="image3"></div>
                                                        <div className="group161">                            <div className="digitalMarketing">{rootStore.commonStore.digitalMarket}</div>
                                                                <div className="digitalMarketPromotes">{rootStore.commonStore.dmpromotes}</div>
                                                                        <div className="getStartedb3bf5309">


                                                                                <button className="getStartedaff7fff0"
                                                                                        onMouseLeave={change2Background}
                                                                                        onMouseOver={changeBackground}
                                                                                        type="submit" onClick={() => {
                                                                                                history.push("/contactus");
                                                                                                rootStore.commonStore.purpose = "Digital Marketing"

                                                                                        }}>{rootStore.commonStore.lets_go}</button>


                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="buisnessIntelligence">                        <div className="businessIntelligenceAnalytics07e1bd88">                            <div className="group160">                                <div className="businessIntelligenceAnalytics">{rootStore.commonStore.businessAnalytics}</div>
                                                                <div className="weCanHelp">{rootStore.commonStore.weCanHelp}</div>
                                                                <div className="dataisoil">{rootStore.commonStore.datanewOil}</div>
                                                                <div className="getStarted7a92bdff">
                                                                        <button className="getStarted"
                                                                                onMouseLeave={change2Background}
                                                                                onMouseOver={changeBackground}
                                                                                type="submit" onClick={() => {
                                                                                        history.push("/contactus");

                                                                                        rootStore.commonStore.purpose = "Business Analytics"
                                                                                }}>{rootStore.commonStore.getsStarted_button}</button>


                                                                </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                </div>
                                        </div>

                                </div>


                        </div>
                                <div style={{ marginTop: "2800px" }}>
                                        <DesktopFooter />
                                </div>
                        </div>


                        );
}



                        export default observer(Purimhome14);
