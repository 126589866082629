
import React, { useContext } from "react";


import "./../../styles/mobile-project.css";
import { observer } from "mobx-react-lite";
import { history } from '../../index';
import { RootStoreContext } from "../../store/rootStore";
import DesktopFooter from "../component/footer";

const PurimTechnologiesHomeMobile: React.FC = () => {

        const rootStore = useContext(RootStoreContext);
        function changeBackground(e: any) {
                e.target.style.background = "orange";
        }

        function change2Background(e: any) {
                e.target.style.background = "orangeRed";
        }

        return (
                <div>
                        <div className="purimTechnologiesProjectMobile">        <div className="rectangle124"></div>
                                <div className="projects">{rootStore.commonStore.projects}</div>
                                <div className="group162">            <div className="mission360b15b1408">{rootStore.commonStore.mission360}</div>
                                        <div className="missionsubContent">{rootStore.commonStore.mission360SubContent}</div>
                                        <div className="mission360Application">{rootStore.commonStore.applicationMission}</div>
                                        <div className="rolebased">{rootStore.commonStore.roleBased}</div>
                                       {/*  <div className="mainBtnf2acdadd">                <div className="group44d2edd03e">                    <svg preserveAspectRatio="none" viewBox="0 0 146.788818359375 32" className="path217f9ad1762"><path d="M 3.535075187683105 0 L 143.2537384033203 0 C 145.2061157226562 0 146.788818359375 1.59187650680542 146.788818359375 3.55555534362793 L 146.788818359375 28.44444274902344 C 146.788818359375 30.40812873840332 145.2061157226562 32 143.2537384033203 32 L 3.535075187683105 32 C 1.582707047462463 32 0 30.40812873840332 0 28.44444274902344 L 0 3.55555534362793 C 0 1.59187650680542 1.582707047462463 0 3.535075187683105 0 Z" /></svg>
                                                <div className="goToWebsite00d64115">{rootStore.commonStore.goTOwebSite}</div>
                                        </div>
                                        </div> */}
                                </div>
                                <svg preserveAspectRatio="none" viewBox="0 0 12 11" className="polygon2"><path d="M 6 0 L 12 11 L 0 11 Z" /></svg>
                             {/*    <div className="maskGroup9"></div>
                                <div className="digitalOrganisationDevelopmentc5b3b3bf">{rootStore.commonStore.dod}</div>
                                <div className="cimsOrganisationDevelopmentOdProgrammeHasBeenFormulatedToImproveOrganizationsAndItsLeadersInChristianOrganizationsWithTheoryAndPracticeOfPlannedChangeItIsASystematicProcessWhichAimsToStudyTheSystemsAndProceduresAdodc02f155">{rootStore.commonStore.cimOrganization}</div>
                                <div className="theDigitalOrganisationDevelopmentProjectAimsToDigitaliseTheOrganisationProgramUsingAnOnlineAssessmentFormAndOnlineDashboardreportc76f7124">{rootStore.commonStore.dodAim}</div>
                                <div className="xSelfRegistrationByOrganisationsRegistrationApprovalByCimOnlineAssessmentByOrganisationsAssessmentReviewByCimOrganisationEndorsementByFederations1383a480">{rootStore.commonStore.dodFeature}</div> */}
                             {/*    <div className="rectangle66"></div> */}
                              {/*   <div className="group151">            <div className="mobileWebAppDevelopment03da7ce8">                <div className="rectangle42c50be519"></div>
                                        <div className="maskGroup4d8bc3077"></div>
                                </div>
                                        <div className="technology59be24e4">{rootStore.commonStore.technology}</div>
                                        <div className="reactJs">{rootStore.commonStore.reactJsforWeb}</div>
                                </div> */}
                                <div className="group153">            <div className="mobileWebAppDevelopment">                <div className="rectangle42"></div>
                                        <div className="maskGroup4"></div>
                                </div>
                                        <div className="technology">{rootStore.commonStore.technology}</div>
                                        <div className="frontEndFlutter">{rootStore.commonStore.frontEndWebApp}</div>
                                </div>
                                <div className="mission360"></div>
                                {/*  <div className="group164">           <div className="group163">                <div className="digitalOrganisationDevelopment">{rootStore.commonStore.dod}</div>
                                        <div className="cimOrg">{rootStore.commonStore.cimOrganization}</div>
                                        <div className="digitalOrgan">{rootStore.commonStore.dodAim}</div>
                                        <div className="selfRegister">{rootStore.commonStore.dodFeature}</div>
                                </div>
                                        <div className="mainBtn">                <div className="group44">                    <svg preserveAspectRatio="none" viewBox="0 0 146 32" className="path217"><path d="M 3.516078233718872 0 L 142.4839172363281 0 C 144.4257965087891 0 146 1.59187638759613 146 3.555555582046509 L 146 28.44444465637207 C 146 30.40812492370605 144.4257965087891 32 142.4839172363281 32 L 3.516078233718872 32 C 1.574201822280884 32 0 30.40812492370605 0 28.44444465637207 L 0 3.555555582046509 C 0 1.59187638759613 1.574201822280884 0 3.516078233718872 0 Z" /></svg>
                                                <div className="goToWebsite">{rootStore.commonStore.goTOwebSite}</div>
                                        </div>
                                        </div>
                             </div>
  */}  
    <div style={{ marginTop: "1500.00px" }}>
                                <DesktopFooter />
                        </div>
                        </div>
                        
                      
                </div>

        );
}




export default observer(PurimTechnologiesHomeMobile);
