
import React, { useContext } from "react";


import "./../../styles/footer.css";
import { observer } from "mobx-react";

import { history } from '../../index';
import { RootStoreContext } from "../../store/rootStore";
import SocialIcon from "../component/social_icon";
import { Button, Responsive, Segment } from "semantic-ui-react";



const DesktopFooter: React.FC = () => {

    const rootStore = useContext(RootStoreContext);



    return (<div>
        <Responsive minWidth={1200}>
            <div className="group167">
                <div className="footer">                    <div className="footerBackgroud"></div>
                    <div className="footerContent">                        <div className="rightFooterContent">
                        <div className="home"
                            style={{ cursor: "pointer" }}
                            onClick={() =>{ 
                                history.push("/");
                                window.scrollTo({ left: 0, top: 0, behavior: "smooth" })}}>{rootStore.commonStore.home}</div>
                        <div className="servicesfe62e189"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                history.push("/");
                                setTimeout(() =>{
                                    window.scrollTo({ left: 0, top: 600, behavior: "smooth" })
                                })
                              }}>{rootStore.commonStore.services}</div>
                        <div className="projects"
                            style={{ cursor: "pointer" }}
                            onClick={() => { history.push("/projects"); }}>{rootStore.commonStore.projects}</div>
                        {/*   <div className="contact2faf6af6">{rootStore.commonStore.contact_foot}</div> */}

                    </div>
                        <div className="contacteab979c7">                            <div className="contact">{rootStore.commonStore.contact_foot}</div>
                            <div className="phone919840357534emailContact">{rootStore.commonStore.phone}<br />{rootStore.commonStore.mail}<br /><br />{rootStore.commonStore.addrss}</div>
                            <div style={{ marginTop: "210px" }}>
                                <SocialIcon />
                            </div>


                            <div className="x2021ByPurimTechnologies">{rootStore.commonStore.copyright}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Responsive>
        <Responsive maxWidth={1199} minWidth={768} >
            <div>

                <div className="section2b62aebb9"></div>
                <div className="maskGroup6"></div>
                <div className="asset518734dab"></div>
                <div className="group113">            <div className="contact8273d781">{rootStore.commonStore.contact_foot}</div>
                    <div className="phone919840357534emailContact">{rootStore.commonStore.phone}<br />{rootStore.commonStore.mail}<br /><br />{rootStore.commonStore.addrss}</div>
                    <div className="x2021ByPurimTechnologies">{rootStore.commonStore.copyright}</div>
                    <div style={{ marginTop: "200px" }}>
                        <tr>
                            <a href="https://www.facebook.com/purimtechnologies" target="_blank">
                                < Button className="ui circular facebook icon button"  >
                                    <i className="facebook icon" ></i>

                                </Button>
                            </a>

                            <a href="https://www.linkedin.com/company/purim-technologies/" target="_blank">
                                <button className="ui circular facebook icon button" >
                                    <i className="linkedin icon"></i>
                                </button>
                            </a>
                            <button className="ui circular google plus icon button" onClick={() => {
                                rootStore.commonStore.purpose = ""
                                history.push("/contactus")
                            }} >
                                <i className="envelope icon"></i>
                            </button>
                        </tr>
                    </div>
                    <div className="group112">
                        <div className="home"
                            style={{ cursor: "pointer" }}
                            onClick={() =>{ history.push("/");
                                window.scrollTo({ left: 0, top: 0, behavior: "smooth" })}}
                        >{rootStore.commonStore.home}</div>
                        <div className="services"
                            style={{ cursor: "pointer" }}
                            onClick={() => { history.push("/"); setTimeout(() => { window.scrollTo({ behavior: "smooth", top: 900 }); }, 100); }}
                        >{rootStore.commonStore.services}</div>
                        <div className="projects"
                            style={{ cursor: "pointer" }}
                            onClick={() => { history.push("/projects"); }}
                        >{rootStore.commonStore.projects}</div>

                    </div>
                </div>
            </div>


        </Responsive>
        <Responsive maxWidth={767} minWidth={300} >
            <div className="rectangle122"></div>
            <div className="group161">            <div className="contact">{rootStore.commonStore.contact_foot}</div>
                <div className="phone919840357534emailContact">{rootStore.commonStore.phone}<br />{rootStore.commonStore.mail}<br /><br />{rootStore.commonStore.addrss}</div>
                <div style={{ marginTop: "145px" }}>
                    <tr>
                        <a href="https://www.facebook.com/purimtechnologies" target="_blank">
                            <button className="ui circular facebook icon button">
                                <i className="facebook icon"></i>

                            </button>
                        </a>

                        <a href="https://www.linkedin.com/company/purim-technologies/" target="_blank">
                            <button className="ui circular facebook icon button">
                                <i className="linkedin icon"></i>
                            </button>
                        </a>
                        <button className="ui circular google plus icon button" onClick={() => {
                            rootStore.commonStore.purpose = ""
                            history.push("/contactus")
                        }} >
                            <i className="envelope icon"></i>
                        </button>
                    </tr>
                </div>
                <div className="x2021ByPurimTechnologies">{rootStore.commonStore.copyright}</div>
            </div>
            <div className="rectangle123"></div>

        </Responsive>
    </div>

    );
}



export default observer(DesktopFooter);
