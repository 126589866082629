import React, { Fragment, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { mediaQueries } from './responsive';
import {Toaster} from 'react-hot-toast';


import PurimTechnologiesHomeTablet from './features/tablet/purimTechnologiesHomeTablet';

import { useMediaQuery } from 'react-responsive';

import Purimhome14 from './features/desktop/purimhome14';
import PurimTechnologiesHomeMobile from './features/mobile/purimTechnologiesHomeMobile';
import PurimTechnologies404ErrorMobile from './features/mobile/PurimTechnologies404ErrorMobile';
import PurimTechnologiesProjectTablet from './features/tablet/purimTechnologiesProjectTablet';
import purimTechnologiesProjectMobile from './features/mobile/purimTechnologiesProjectMobile';
import PurimTechnologiesProjectsDesktop from './features/desktop/purimTechnologiesProjectsDesktop';
import PurimTechnologies404ErrorDesktop from './features/desktop/purimTechnologies404ErrorDesktop';
import PurimTechnologies404ErrorTablet from './features/tablet/purimTechnologies404ErrorTablet';
import ContactUs from './features/desktop/contactus';
import { Container } from 'semantic-ui-react';
import AppNavBar from './layout/AppNavBar';
import axios from 'axios';

function App() {

  const isMobile = useMediaQuery({ query: mediaQueries.mobileQuery });
  const isTablet = useMediaQuery({ query: mediaQueries.tabletQuery });
  const isDesktop14 = useMediaQuery({ query: mediaQueries.desktop14inchQuery });

useEffect(()=>{
  axios.get("http://184.168.127.122/api/Visitor"); 
// axios.get("http://localhost:5000/api/Visitor"); 
},[])

  
 
  return (
    <Fragment>
     <AppNavBar childComponent={
       
         <Route exact path="/" component={isMobile ? PurimTechnologiesHomeMobile : isTablet ? PurimTechnologiesHomeTablet : Purimhome14} />
        
     }/>
    
      <Route
        path={"/(.+)"}

        render={() => (
        <Fragment>
          <AppNavBar childComponent={
          <Container style={{marginTop:"13vh"}}>


          <Switch>
       
        
            <Route path="/home" component={isMobile ? PurimTechnologiesHomeMobile : isTablet ? PurimTechnologiesHomeTablet : Purimhome14} />
            <Route path="/projects" component={isMobile ? purimTechnologiesProjectMobile : isTablet ? PurimTechnologiesProjectTablet : PurimTechnologiesProjectsDesktop} />
            <Route path="/contactus" component={ContactUs} />
            <Route component={isMobile ? PurimTechnologies404ErrorMobile : isTablet ? PurimTechnologies404ErrorTablet : PurimTechnologies404ErrorDesktop} />
           



          </Switch>
        
          </Container>
  }/>
          </Fragment>
         
        )}
      />
       <Toaster/>

    </Fragment>
  )
}

export default App;

