




import React, { Fragment, useContext, useState } from "react";

import serialize from "form-serialize";



import "./../../styles/contact.css";
import "react-widgets/dist/css/react-widgets.css";
import { observer } from "mobx-react";
import { Button, Container, Form, Grid,Segment,Responsive } from "semantic-ui-react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Combobox from "react-widgets/lib/Combobox";
import { RootStoreContext } from "../../store/rootStore";
import SocialIcon from "../component/social_icon";

const ContactUs = () => {
  const [loading, isLoading] = useState(false);
  const rootStore = useContext(RootStoreContext);
  const [purpose, setPurpose] = useState(rootStore.commonStore.purpose);
  return (
    <div>
     
    <Responsive minWidth={1200} >
    <Grid className="ui two column  aligned grid" doubling>
   
      <Grid.Row>
      
        <Grid.Column style={{ alignContent: "center"}}  >
       
          <Segment>
            
        
            <Container textAlign="left" >

              <Form

                onSubmit={event => {
                  toast.success("Thank you!" + "\n" + "We will get back to you soon..", {
                    duration: 3500,
                    position: 'top-center',
                    style: {
                      fontWeight: "bolder",
                      height: "70px",
                      border: '1px solid black',
                      backgroundColor: "#60148a",
                      color: "white"

                    },
                  });
                  isLoading(true);
                  try {

                    event.preventDefault();
                    const fieldData = serialize(event.currentTarget, { hash: true });
                    const {
                      name: Name,

                      email: email,
                      phoneNumber: phoneNumber,
                      purpose: purpose,
                      heardAboutUsFrom: heardAboutUsFrom,
                      shortdescription: shortdescription
                    } = fieldData;
                    console.log(fieldData);
                  
                    axios.post("http://184.168.127.122/api/Contactus/contactus", fieldData);
                        //axios.post("http://localhost:5000/api/Contactus/contactus",fieldData); 
                        console.log(fieldData);  
                    setTimeout(() => { isLoading(false); }, 1000);

                  } catch (error) {
                    console.log(error);
                    setTimeout(() => { isLoading(false); }, 1000);
                  }
                }}
              >
                <div className="field">
                  <label>Name</label>
                  <input type="text" name="Name" required />
                </div>

                <div className="field">
                  <label>Email Address</label>
                  <input type="email" name="email" required />
                </div>
                <div className="field">
                  <label>Mobile Number</label>
                  <input type="tel" name="phoneNumber" /* required pattern="\d{10}" */ />
                </div>
                <div className="field">
                  <label>Purpose</label>
                  <Combobox
                    defaultValue={purpose}
                    data={["Book a Meeting", "Mobile/Web app Development", "Business Analytics", "Digital Marketing","Others"]}
                    inputProps={{ required: true, name: "purpose" }}
                    name="purpose"


                  />
                </div>


                <div className="field">
                  <label>Description</label>
                  <input style={{height:"80px"}} name="shortdescription" />
                </div>

                <Button className="ui red right labeled icon button" color="blue" loading={loading}>
                  <i className="envelope icon"></i>
                  Send Email
                </Button>
                {/*  <Button className="contactButton" type="submit">Contact Us!</Button> */}
              </Form>

            </Container>
          </Segment>
        </Grid.Column>


        <Grid.Column style={{ alignContent: "center"}}>

          <div style={{ overflow:"hidden"}}>
            <div className="contactus">        <div className="group1">            <div className="background"></div>
              <div className="contactuscontent">
                <div className="contact">Contact</div>
                <div className="phone919840357534emailContact">Phone : +91 9840 357 534<br />Email : contact@purimtechnologies.com<br /><br />Chennai, India</div>
                <div className="x2021ByPurimTechnologies">©2021 by Purim Technologies.</div>
                <div>
                  <SocialIcon />
                </div>
              </div>
            </div>
            </div>



          </div>

        </Grid.Column>








      </Grid.Row>
    </Grid>
    </Responsive>
    <Responsive maxWidth={1199} minWidth={768}>
    <Grid className="ui two column  aligned grid" doubling>
      <Grid.Row>
      <div style={{
                      fontWeight: "bold",
                      
                      fontSize: "30px",
                   
                      color: "red",
             marginTop: "35px"
                    }}>
            Contact Us...
          </div>
        <Grid.Column style={{ alignContent: "center"}}  >
          <Segment>
            <Container textAlign="left" >

              <Form

                onSubmit={event => {
                  toast.success("Thank you!" + "\n" + "We will get back to you soon..", {
                    duration: 3500,
                    position: 'top-center',
                    style: {
                      fontWeight: "bolder",
                      height: "70px",
                      border: '1px solid black',
                      backgroundColor: "#60148a",
                      color: "white"

                    },
                  });
                  isLoading(true);
                  try {

                    event.preventDefault();
                    const fieldData = serialize(event.currentTarget, { hash: true });
                    const {
                      name: Name,

                      email: email,
                      phoneNumber: phoneNumber,
                      purpose: purpose,
                      heardAboutUsFrom: heardAboutUsFrom,
                      shortdescription: shortdescription
                    } = fieldData;
                    console.log(fieldData);
                  
                    axios.post("http://184.168.127.122/api/Contactus/contactus", fieldData);
                        //axios.post("http://localhost:5000/api/Contactus/contactus",fieldData); 
                        console.log(fieldData);  
                    setTimeout(() => { isLoading(false); }, 1000);

                  } catch (error) {
                    console.log(error);
                    setTimeout(() => { isLoading(false); }, 1000);
                  }
                }}
              >
                <div className="field">
                  <label>Name</label>
                  <input type="text" name="Name" required />
                </div>

                <div className="field">
                  <label>Email Address</label>
                  <input type="email" name="email" required />
                </div>
                <div className="field">
                  <label>Mobile Number</label>
                  <input type="tel" name="phoneNumber" /* required pattern="\d{10}" */ />
                </div>
                <div className="field">
                  <label>Purpose</label>
                  <Combobox
                    defaultValue={purpose}
                    data={["Book a Meeting", "Mobile/Web app Development", "Business Analytics", "Digital Marketing","Others"]}
                    inputProps={{ required: true, name: "purpose" }}
                    name="purpose"


                  />
                </div>


                <div className="field">
                  <label>Description</label>
                  <input style={{height:"80px"}} name="shortdescription" />
                </div>

                <Button className="ui red right labeled icon button" color="blue" loading={loading}>
                  <i className="envelope icon"></i>
                  Send Email
                </Button>
                {/*  <Button className="contactButton" type="submit">Contact Us!</Button> */}
              </Form>

            </Container>
          </Segment>
        </Grid.Column>


        <Grid.Column style={{ alignContent: "center",top:"35px"}}>

          <div style={{ overflow:"hidden"}}>
            <div className="contactus">        <div className="group1">            <div className="background"></div>
              <div className="contactuscontent">
                <div className="contact">Contact</div>
                <div className="phone919840357534emailContact">Phone : +91 9840 357 534<br />Email : contact@purimtechnologies.com<br /><br />Chennai, India</div>
                <div className="x2021ByPurimTechnologies">©2021 by Purim Technologies.</div>
                <div>
                  <SocialIcon />
                </div>
              </div>
            </div>
            </div>



          </div>

        </Grid.Column>








      </Grid.Row>
    </Grid>
    </Responsive>
    <Responsive maxWidth={767} >
    <Grid className="ui two column  aligned grid" doubling>
      <Grid.Row>
      <div style={{
                      fontWeight: "bold",
                      
                      fontSize: "25px",
                   
                      color: "red",
             marginTop: "35px"
                    }}>
            Contact Us...
          </div>
        <Grid.Column style={{ alignContent: "center"}}  >
          <Segment>
            <Container textAlign="left" >

              <Form

                onSubmit={event => {
                  toast.success("Thank you!" + "\n" + "We will get back to you soon..", {
                    duration: 3500,
                    position: 'top-center',
                    style: {
                      fontWeight: "bolder",
                      height: "70px",
                      border: '1px solid black',
                      backgroundColor: "#60148a",
                      color: "white"

                    },
                  });
                  isLoading(true);
                  try {

                    event.preventDefault();
                    const fieldData = serialize(event.currentTarget, { hash: true });
                    const {
                      name: Name,

                      email: email,
                      phoneNumber: phoneNumber,
                      purpose: purpose,
                      heardAboutUsFrom: heardAboutUsFrom,
                      shortdescription: shortdescription
                    } = fieldData;
                    console.log(fieldData);
                  
                    axios.post("http://184.168.127.122/api/Contactus/contactus", fieldData);
                        //axios.post("http://localhost:5000/api/Contactus/contactus",fieldData); 
                        console.log(fieldData);  
                    setTimeout(() => { isLoading(false); }, 1000);

                  } catch (error) {
                    console.log(error);
                    setTimeout(() => { isLoading(false); }, 1000);
                  }
                }}
              >
                <div className="field">
                  <label>Name</label>
                  <input type="text" name="Name" required />
                </div>

                <div className="field">
                  <label>Email Address</label>
                  <input type="email" name="email" required />
                </div>
                <div className="field">
                  <label>Mobile Number</label>
                  <input type="tel" name="phoneNumber" /* required pattern="\d{10}" */ />
                </div>
                <div className="field">
                  <label>Purpose</label>
                  <Combobox
                    defaultValue={purpose}
                    data={["Book a Meeting", "Mobile/Web app Development", "Business Analytics", "Digital Marketing","Others"]}
                    inputProps={{ required: true, name: "purpose" }}
                    name="purpose"


                  />
                </div>


                <div className="field">
                  <label>Description</label>
                  <input style={{height:"80px"}} name="shortdescription" />
                </div>

                <Button className="ui red right labeled icon button" color="blue" loading={loading}>
                  <i className="envelope icon"></i>
                  Send Email
                </Button>
                {/*  <Button className="contactButton" type="submit">Contact Us!</Button> */}
              </Form>

            </Container>
          </Segment>
        </Grid.Column>


    








      </Grid.Row>
    </Grid>
    </Responsive>
    </div>
  );
}


export default observer(ContactUs);