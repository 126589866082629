
import React, { useContext } from "react";


import "./../../styles/mobile-home.css";
import { observer } from "mobx-react-lite";
import { history } from '../../index';
import { RootStoreContext } from "../../store/rootStore";
import DesktopFooter from "../component/footer";

const PurimTechnologiesHomeMobile: React.FC = () => {

        const rootStore = useContext(RootStoreContext);
        function changeBackground(e: any) {
                e.target.style.background = "orange";
        }

        function change2Background(e: any) {
                e.target.style.background = "orangeRed";
        }

        return (
                <div>
                        <div className="purimTechnologiesHomeMobile1">        <div className="rectangle102"></div>
                                <div className="group151">            <div className="group158">                <div className="maskGroup18"></div>
                                        <div className="laptopNew30000"></div>
                                </div>
                                </div>
                                <div className="group150">            <div className="turnYourDigitalVisionIntoReality">{rootStore.commonStore.turnyour}</div>
                                        <div className="shareYourVisionAndWeWillShareAProposalOnHowToMakeAReality">{rootStore.commonStore.shareyour}</div>
                                        <div className="mainBtn642bdc04">
                                                <button className="contactUs"
                                                        onMouseLeave={change2Background}
                                                        onMouseOver={changeBackground}
                                                        type="submit" onClick={() => window.scrollTo({ left: 0, top: 3800, behavior: "smooth" })}>{rootStore.commonStore.contactUs_button}</button>


                                        </div>
                                        <div className="weBelieveThatTechnologyShouldSupportAndEnhanceYourOrganizationsSuccessOurWideRangeOfBestInClassServicesProvidesCustomizedSolutionsThatFitUniqueItNeeds">We believe that technology should support and enhance your organization’s success. Our wide range of best-in-class services provides customized solutions that fit unique IT needs.</div>
                                </div>
                                <div className="rectangle119"></div>
                                <div className="image2"></div>
                                <div className="rectangle120"></div>
                                <div className="group159">            <div className="services">{rootStore.commonStore.services}</div>
                                        <div className="wedeliver">{rootStore.commonStore.weDeliver}</div>
                                        <div className="mainBtn51f24702">
                                                <button className="bookAMeeting"
                                                        onMouseLeave={change2Background}
                                                        onMouseOver={changeBackground}
                                                        type="submit" onClick={() => {
                                                                history.push("/contactus");
                                                                rootStore.commonStore.purpose = "Book a Meeting"
                                                        }}>{rootStore.commonStore.bookaMeeting_button}</button>

                                        </div>
                                </div>
                                <div className="crane0000"></div>
                                <div className="group155">            <div className="mobileWebAppDevelopmentf8baf0f7">                <div className="rectangle4253d06cc9"></div>
                                        <div className="maskGroup16bb57ac60"></div>
                                </div>
                                        <div className="mobileWebAppDevelopment9aefd416">{rootStore.commonStore.mobileandweb}</div>
                                        <div className="builpowerful">{rootStore.commonStore.buildPowerful}</div>
                                        <div className="mainBtn4f57435c">

                                                <button className="letsDevelopAnApp"
                                                        onMouseLeave={change2Background}
                                                        onMouseOver={changeBackground}
                                                        type="submit" onClick={() => {
                                                                history.push("/contactus");

                                                                rootStore.commonStore.purpose = "Mobile/Web app Development"
                                                        }}>{rootStore.commonStore.letsDevelop_button}</button>



                                        </div>
                                </div>
                                <div className="businessAnalytics0000"></div>
                                <div className="group156">            <div className="mobileWebAppDevelopment">                <div className="rectangle42a091976f"></div>
                                        <div className="maskGroup16"></div>
                                </div>
                                        <div className="businessIntelligenceAnalytics70ce7642">{rootStore.commonStore.businessAnalytics}</div>
                                        <div className="weCanHelp">{rootStore.commonStore.weCanHelp}</div>
                                        <div className="dataisoil7b048b2b">{rootStore.commonStore.datanewOil}</div>
                                        <div className="mainBtn7288805f">
                                                <button className="getStarted4755a7b7"
                                                        onMouseLeave={change2Background}
                                                        onMouseOver={changeBackground}
                                                        type="submit" onClick={() => {
                                                                history.push("/contactus");
                                                                rootStore.commonStore.purpose = "Business Analytics"
                                                        }}>{rootStore.commonStore.getsStarted_button}</button>


                                        </div>
                                </div>
                                <div className="digitalMarketing0000"></div>
                                <div className="group138">            <div className="businessIntelligenceAnalyticsc99e39a4">                <div className="rectangle42"></div>
                                        <div className="maskGroup15"></div>
                                </div>
                                        <div className="businessIntelligenceAnalytics">{rootStore.commonStore.digitalMarket}</div>
                                        <div className="datanewOil">{rootStore.commonStore.dmpromotes}</div>

                                                <div className="mainBtn">
                                                        <button className="getStarted"
                                                                onMouseLeave={change2Background}
                                                                onMouseOver={changeBackground}
                                                                type="submit" onClick={() => {
                                                                        history.push("/contactus");
                                                                        rootStore.commonStore.purpose = "Digital Marketing"
                                                                }}>{rootStore.commonStore.lets_go}</button>


                                                </div>
                                        </div>
                                        {/* <div className="rectangle121"></div>
                                        <div className="group160">            <div className="ourClients">{rootStore.commonStore.ourClients}</div>
                                                <div className="whiteCimLogo"></div>
                                                <div className="whiteCmctLogo"></div>
                                                <div className="whiteWorldVisionLogo"></div>
                                        </div> */}
                                        <div style={{ marginTop: "3700.00px" }}>
                                        <DesktopFooter />
                                </div>
                                </div>
                               
                        </div>
                        );

}




                        export default observer(PurimTechnologiesHomeMobile);
