
import React, { useContext } from "react";
import PropTypes from "prop-types";

import "./../../styles/tablet-project.css";
import { observer } from "mobx-react";
import DesktopFooter from "../component/footer";
import { RootStoreContext } from "../../store/rootStore";

const PurimTechnologiesProjectTablet: React.FC = () => {



        const rootStore = useContext(RootStoreContext);


        return (
                <div>
                        <div className="purimTechnologiesProjectTablet">  
                              <div className="projects">{rootStore.commonStore.projects}</div>
                              {/*    <svg preserveAspectRatio="none" viewBox="0 0 12 11" className="polygon2"><path d="M 6 0 L 12 11 L 0 11 Z" /></svg>
                               <div className="maskGroup9"></div>
                                <div className="digitalOrganisationDevelopment">{rootStore.commonStore.dod}</div>
                                <div className="cimOrg">{rootStore.commonStore.cimOrganization}</div>
                                <div className="digitalOrgan">{rootStore.commonStore.dodAim}</div>
                                <div className="selfRegister">{rootStore.commonStore.dodFeature}</div>

                                <div className="rectangle66"></div>
                                <div className="group151">            <div className="mobileWebAppDevelopment">                <div className="rectangle42"></div>
                                        <div className="maskGroup4"></div>
                                </div>
                                        <div className="technology">{rootStore.commonStore.technology}</div>
                                        <div className="reactJs">{rootStore.commonStore.reactJsforWeb}</div>
                                </div>
                                */}
                                <div className="group153">            <div className="mobileWebAppDevelopment2ca92495">                <div className="rectangle42ba8a0a76"></div>
                                        <div className="maskGroup46f18d883"></div>
                                </div>
                                        <div className="technologye824a97d">{rootStore.commonStore.technology}</div>
                                        <div className="frontEndFlutter">{rootStore.commonStore.frontEndWebApp}</div>
                                </div> 
                                <div className="mission360"></div>
                                <div className="group163">            <div className="digitalOrganisationDevelopmentce43c231">{rootStore.commonStore.dod}</div>
                                        <div className="cimOrgDevelop">{rootStore.commonStore.cimOrganization}</div>
                                        <div className="dodDevlopment">{rootStore.commonStore.dodAim}</div>
                                        <div className="selfRegister">{rootStore.commonStore.dodFeature}</div>
                                </div>
                               {/*  <div className="mainBtn">            <div className="group44">                <svg preserveAspectRatio="none" viewBox="0 0 207.61767578125 45" className="path217"><path d="M 5 0 L 202.61767578125 0 C 205.3791046142578 0 207.61767578125 2.238576173782349 207.61767578125 5 L 207.61767578125 40 C 207.61767578125 42.76142501831055 205.3791046142578 45 202.61767578125 45 L 5 45 C 2.238576173782349 45 0 42.76142501831055 0 40 L 0 5 C 0 2.238576173782349 2.238576173782349 0 5 0 Z" /></svg>
                                        <div className="goToWebsite">{rootStore.commonStore.goTOwebSite}</div>
                                </div>
                                </div> */}
                                <div className="rectangle124"></div>
                                <div className="group164">        
                                    <div className="missionsubContent">{rootStore.commonStore.mission360SubContent}</div>
                                        <div className="mission360Application">{rootStore.commonStore.applicationMission}</div>
                                        <div className="rolebased">{rootStore.commonStore.roleBased}</div>
                                        <div className="mainBtn664969cc">      
                                                  <div className="group447bff6726">      
                                                                <svg preserveAspectRatio="none" viewBox="0 0 207.61767578125 45" className="path2177690a7be"><path d="M 5 0 L 202.61767578125 0 C 205.3791046142578 0 207.61767578125 2.238576173782349 207.61767578125 5 L 207.61767578125 40 C 207.61767578125 42.76142501831055 205.3791046142578 45 202.61767578125 45 L 5 45 C 2.238576173782349 45 0 42.76142501831055 0 40 L 0 5 C 0 2.238576173782349 2.238576173782349 0 5 0 Z" /></svg>
                                                <div className="goToWebsiteaa9776b6">{rootStore.commonStore.goTOwebSite}
                                                </div>
                                        </div>
                                        </div>
                                        <div className="mission36074fef686">{rootStore.commonStore.mission360}</div>
                                </div>
                                <div style={{ marginTop: "1660px" }}>
                                <DesktopFooter />
                        </div>
                        </div>
                      

                </div>

        );
}



export default observer(PurimTechnologiesProjectTablet);
