
import React, { useContext } from "react";


import "./../../styles/desktop-404.css";
import { observer } from "mobx-react";
import {history} from '../../index';
import { RootStoreContext } from "../../store/rootStore";
import { Button } from "semantic-ui-react";

const PurimTechnologies404ErrorDesktop:React.FC=()=>{
  const rootStore=useContext(RootStoreContext);
 
    
    return (
      <div className="purimTechnologies404ErrorDesktop">        <div className="group162">            <div className="rectangle66"></div>
      </div>
     
        <div className="group163">            <div className="x404">404</div>
          <div className="thePageYouAreLookingForWasMovedRemovedRenamedOrMightNeverHaveExited">The page you are looking for was moved, removed, renamed or might never have exited.</div>
          <div className="pageNotFound">Page not found.</div>
          <div className="mainBtn806dd029">                              
           
            <button className="contactUs"
                                     /*    onMouseLeave={change2Background}
                                        onMouseOver={changeBackground} */
                                           type="submit" onClick={() => {  history.push("/contactus");
                                                                    
                                            rootStore.commonStore.purpose=""}
                                           }>Contact Us</button>
          
          </div>
          <div className="mainBtn">                              
           
           <Button  style={{backgroundColor: 'white', borderRadius: 3,size:'large',color:"red"}} size='large'
                                    /*    onMouseLeave={change2Background}
                                       onMouseOver={changeBackground} */
                                          type="submit" onClick={() =>
                                         {  history.push("/");
                                                                   
                                          }
                                          }>Go Home</Button>
         
         </div>
         
        </div>
        <div className="group164">            <div className="asset4"></div>
          <div className="x404error0000"></div>
        </div>
        
      </div>
    );
  }





export default observer(PurimTechnologies404ErrorDesktop);
          