import { observable, action, runInAction } from "mobx";

import { RootStore } from "./rootStore";



export default class CommonStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

  }
  @observable purpose = "";
  @observable turnyour = "Turn your \nDigital Vision \ninto Reality ";
  @observable shareyour = "Share your vision & we will \npartner with you to make it a reality";
  @observable weBelive = "We believe that technology should support and enhance your organization’s success.\nOur wide range of best-in-class services provides customized solutions that fit your unique Digital needs.";
  @observable contactUs_button = "Contact Us";
  @observable lets_go = "Let's Go Digital";
  @observable services = "Services";
  @observable weDeliver = "We deliver quality services in the most efficient way, and our experts will work collaboratively with you to customize our offerings to your particular needs. Book a meeting with one of our consultants to hear more about how we can assist your operation.";
  @observable bookaMeeting_button = "Book a Meeting";
  @observable mobileandweb = "Mobile & Web \nApp Development";
  @observable letsDevelop_button = "Lets Develop An App";
  @observable buildPowerful = "Build powerful mobile & web apps to simplify and automate your business processes to make the optimal use of available resources. Mobile have become an integral part of our life, leveraging it is crucial for the success of your business.";
  @observable businessAnalytics = "Business Intelligence \n& Analytics";
  @observable weCanHelp = "We can help you in the analysis of your structured and unstructured data to draw meaningful insights. Valuable business decision can be derived facilitating your organization to be data-driven.";
  @observable datanewOil = "“Data is the new oil. It’s valuable, but if unrefined it cannot really be used." + "- Clive Humby";
  @observable getsStarted_button = "Get Started";
  @observable digitalMarket = "Digital Marketing";
  @observable dmpromotes = "Digital Marketing promotes the business through online & social media platforms. We analyse your business, competitors, right audience, products to give the perfect analytics. On this basis, we come up with a suitable marketing strategy for your business. We provide services like content marketing,social media marketing and SEO.";
  @observable ourClients = "Our Clients";
  @observable contact_foot = "Contact";
  @observable phone = "Phone : +91 9840 357 534";
  @observable mail = "Email : contact@purimtechnologies.com";
  @observable addrss = "Chennai, India";
  @observable copyright = "©2021 by Purim Technologies.";
  @observable home = "Home";
  @observable projects = "Projects";
  @observable mission360 = "Mission 360";
  @observable mission360SubContent = "Mission360 project is a beneficiary cum donor management system (web based) for managing sponsorship and program operations of non-profit organisations. It also includes a mobile application for field staff.";
  @observable applicationMission = "The application will allow non-profit organisations to manage and facilitate interactions with their donors, beneficiaries. It provides options to send emails, SMS to donors and print letters. Key features include:";
  @observable roleBased = "- Role based access \n - Beneficiary data management \n- Benefits distribution tracking \n- Measure Key Indicators \n- Donor Interaction management \n- Integration with email, SMS \n- Staff Task Management \n- Management reports";
  @observable technology = "Technology";
  @observable reactJsforWeb = "React.js web app for digital organisation development portal. Backend services are developed using .NET Core 3.1 framework and MySQL database.  \n";
  @observable frontEndWebApp = "Frontend web app for donor management system using React.js. Backend services are developed using .NET Core 3.1 framework and Microsoft SQL database. Android Mobile App for Field staff built using Flutter & Dart. Technology to be used for Reports to be finalised. ";
  @observable dod = "Digital Organisation Development";
  @observable cimOrganization = "CIM’s Organisation Development (OD) programme has been formulated to improve organizations and its leaders in Christian organizations with theory and practice of planned change. It is a systematic process, which aims to study the systems and procedures adopted by an organization with the focus to make them function better. It is a comprehensive system change. Using an assessment form, CIM does appraisal of the organization by studying the structural particulars, its systems as well as competence to statutory requirements.";
  @observable dodAim = "The Digital Organisation Development project aims to digitalise the organisation program using an online assessment form and online dashboard/report.";
  @observable dodFeature = `- Self Registration by Organisations\n-Registration Approval by CIM \n- Online Assessment by Organisations \n- Assessment Review by CIM \n- Organisation Endorsement by Federations`;
  
  @observable goTOwebSite = "Go to Website";






}