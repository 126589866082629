import { observer } from "mobx-react-lite";
import "./../../styles/Mobile-404.css";
import {history} from '../../index';
import { Button } from "semantic-ui-react";
const PurimTechnologies404ErrorMobile: React.FC = () => {
  return (
    <div className="purimTechnologies404ErrorMobile">
      {" "}
      <div className="group162">
        {" "}
        <div className="rectangle66"></div>
      </div>
      <div className="group166">
        {" "}
        <div className="group163">
          {" "}
          <div className="x404"> 404</div>
          <div className="thePageYouAreLookingForWasMovedRemovedRenamedOrMightNeverHaveExited">
            The page you are looking for was moved, removed, renamed or might
            never have exited.
          </div>
          <div className="pageNotFound"> Page not found.</div>
          <div className="mainBtnd832fe9f">
            {" "}
            <div className="group447d6218f7">
              {" "}
              <svg
                preserveAspectRatio="none"
                viewBox="0 0 106.848388671875 30"
                className="path217c8c03362"
              >
                <path d="M 3.142599582672119 0 L 103.7057876586914 0 C 105.4413986206055 0 106.848388671875 1.492384195327759 106.848388671875 3.333333492279053 L 106.848388671875 26.66666793823242 C 106.848388671875 28.50761795043945 105.4413986206055 30 103.7057876586914 30 L 3.142599582672119 30 C 1.406989693641663 30 0 28.50761795043945 0 26.66666793823242 L 0 3.333333492279053 C 0 1.492384195327759 1.406989693641663 0 3.142599582672119 0 Z" />
              </svg>
              <div className="contactUs"
              onClick={()=>{history.push("/"); setTimeout(() => {  window.scrollTo({behavior: "smooth",top:4000}); }, 100); }}>contact us</div>
            </div>
          </div>
          <div className="mainBtn">
        
            <div className="group44">
            
             
              <Button className="mainBtn"  onClick={()=>{history.push("/");}}> Go Home</Button>
            </div>
          </div>
        </div>
        <div className="x404error0000"></div>
      </div>
    </div>
  );
};

export default observer(PurimTechnologies404ErrorMobile);
