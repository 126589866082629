
import React from "react";
import PropTypes from "prop-types";

import "./../../styles/tablet-404.css";
import { observer } from "mobx-react";

const PurimTechnologies404ErrorTablet:React.FC=()=> {

 
    
    return (
        <div className="purimTechnologies404ErrorMobile">
        {" "}
        <div className="group162">
          {" "}
          <div className="rectangle66"></div>
        </div>
        <div className="group169">
          {" "}
          <div className="group163">
            {" "}
            <div className="x404"> 404</div>
            <div className="thePageYouAreLookingForWasMovedRemovedRenamedOrMightNeverHaveExited">
              The page you are looking for was moved, removed, renamed or might
              never have exited.
            </div>
            <div className="pageNotFound"> Page not found.</div>
            <div className="mainBtnbedf85a1">
              {" "}
              <div className="group447c505151">
                {" "}
                <svg
                  preserveAspectRatio="none"
                  viewBox="0 0 170 45"
                  className="path2171efc8d6c"
                >
                  <path d="M 5 0 L 165 0 C 167.7614288330078 0 170 2.238576173782349 170 5 L 170 40 C 170 42.76142501831055 167.7614288330078 45 165 45 L 5 45 C 2.238576173782349 45 0 42.76142501831055 0 40 L 0 5 C 0 2.238576173782349 2.238576173782349 0 5 0 Z" />
                </svg>
                <div className="contactUs">contact us</div>
              </div>
            </div>
            <div className="mainBtn">
              {" "}
              <div className="group44">
                {" "}
                <svg
                  preserveAspectRatio="none"
                  viewBox="0 0 151 45"
                  className="path217"
                >
                  <path d="M 4.441176414489746 0 L 146.5588226318359 0 C 149.0116119384766 0 151 2.238576173782349 151 5 L 151 40 C 151 42.76142501831055 149.0116119384766 45 146.5588226318359 45 L 4.441176414489746 45 C 1.988382339477539 45 0 42.76142501831055 0 40 L 0 5 C 0 2.238576173782349 1.988382339477539 0 4.441176414489746 0 Z" />
                </svg>
                <div className="goHome"> Go Home</div>
              </div>
            </div>
          </div>
          <div className="x404error0000"></div>
          <div className="group168">
            {" "}
            <div className="group165">
              {" "}
              <div className="asset4"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }



export default observer(PurimTechnologies404ErrorTablet);
          