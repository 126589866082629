
import React, { Fragment } from 'react'

import { Menu, Segment, Image, Dropdown, Container, Responsive } from 'semantic-ui-react'


import { Link } from 'react-router-dom'

import './../App.css';
import logo from "./../assets/desktop-home/x01finalpurimlogo.png";

import { history } from '../index';
export interface AppNavbarProps {
    childComponent?: React.ReactNode;
    activeItem?: string
}
const AppNavbar: React.FC<AppNavbarProps> = ({ childComponent }) => {
    const [backgroundColor, setBackgroundColor] = React.useState("");
    const [backgroundColor1, setBackgroundColor1] = React.useState("");
    const [backgroundColor2, setBackgroundColor2] = React.useState("");

    const [backgroundColor3, setBackgroundColor3] = React.useState("");
    const [backgroundColor4, setBackgroundColor4] = React.useState("");
    const [color, setColor] = React.useState("");
    const [color1, setColor1] = React.useState("");
    const [color2, setColor2] = React.useState("");

    const [color3, setColor3] = React.useState("");
    const [color4, setColor4] = React.useState("");
    //'#243859'
    const stylehome = {


        color: color, fontWeight: "bolder", fontSize: '1.3em', width: 90, backgroundColor: backgroundColor
    }
    const styleabout = {


        color: color1, fontWeight: "bolder", fontSize: '1.3em', width: 90, backgroundColor: backgroundColor1
    }
    const styleservices = {


        color: color2, fontWeight: "bolder", fontSize: '1.3em', width: 110, backgroundColor: backgroundColor2
    }
    const styleprojects = {


        color: color3, fontWeight: "bolder", fontSize: '1.3em', width: 110, backgroundColor: backgroundColor3
    }
    const stylecontact = {


        color: color4, fontWeight: "bolder", fontSize: '1.3em', width: 160, backgroundColor: backgroundColor4
    }


    return (<Container>


        <Responsive minWidth={1200} className="ui segment basic">
            <Menu
            
                stackable
                borderless

                fixed='top'
                style={{ height: '13vh' }}
            >
                <div style={{ width: 80 }} ></div>

                <Menu.Item header 
                
                style={{ color: '#3f66b5', fontSize: '1em', fontStyle: 'italic', }} >
              
                    <Image src={logo} style={{ height: '10vh' }} /> &nbsp;&nbsp;
                </Menu.Item>


                <Container >
                    <div style={{ width: 520 }} ></div>

                    <Menu.Item 
                    
                    onMouseEnter={() => (setBackgroundColor("#e6443f"), setColor("white"))}
                        onMouseLeave={() => (setBackgroundColor(""), setColor('#243859'))} style={stylehome} as={Link} to='/home' >Home</Menu.Item>

                    <Menu.Item onMouseEnter={() => (setBackgroundColor2("#ff3d00"), setColor2("white"))}
                        onMouseLeave={() => (setBackgroundColor2(""), setColor2('#243859'))} style={styleservices} as={Link} onClick={() => { history.push("/"); setTimeout(() => { window.scrollTo({ behavior: "smooth", top: 700 }); }, 100); }}  >Services</Menu.Item>
                    <Menu.Item onMouseEnter={() => (setBackgroundColor3("#ff3d00"), setColor3("white"))}
                        onMouseLeave={() => (setBackgroundColor3(""), setColor3('#243859'))} style={styleprojects} as={Link} to='/projects' >Projects</Menu.Item>
                    <Menu.Item onMouseEnter={() => (setBackgroundColor4("#ff3d00"), setColor4("white"))}
                        onMouseLeave={() => (setBackgroundColor4(""), setColor4('#243859'))} style={stylecontact} onClick={() => { history.push("/"); setTimeout(() => { window.scrollTo({ behavior: "smooth", top: 3400 }); }, 100); }} >Contact Us</Menu.Item>






                </Container>

            </Menu>



        </Responsive>
        <Responsive maxWidth={1199} minWidth={768}>
            <Menu
                stackable
                borderless

                fixed='top'
                style={{ height: '13vh'}}
            >


                <Menu.Item header style={{ width: "75vw", color: 'white', fontSize: '1em', fontStyle: 'italic', }} >
                    <Image src={logo} style={{ height: '10vh' }} /> &nbsp;&nbsp;
                </Menu.Item>


                <Container >


                    <Fragment>
                        <Menu.Item style={{ width: "20vw" }} >
                            <Menu.Menu position='right'>
                                <Dropdown position='right' icon={{ name: 'sidebar', size: 'huge' }} pointing className='link item' >
                                    <Dropdown.Menu style={{ backgroundColor: 'white' }}>
                                        <Dropdown.Item style={{ color: color2, fontWeight: "bolder", fontSize: '1.3em', width: 110 }} as={Link} to='/home'>Home</Dropdown.Item>
                                        <Dropdown.Item style={{ color: "white", fontWeight: "bolder", fontSize: '1.3em', width: 110 }} as={Link} to='/projects'>Projects</Dropdown.Item>
                                        <Dropdown.Item as={Link} onClick={() => { history.push("/"); setTimeout(() => { window.scrollTo({ behavior: "smooth", top: 890 }); }, 100); }}>Services</Dropdown.Item>
                                        <Dropdown.Item as={Link} onClick={() => { history.push("/"); setTimeout(() => { window.scrollTo({ behavior: "smooth", top: 3000 }); }, 100); }}>Contact Us</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        </Menu.Item>
                    </Fragment>
                </Container>
                
            </Menu>
        </Responsive>
        <Responsive maxWidth={767} >
            <Menu
                stackable
                borderless

                fixed='top'
                style={{ height: '13vh' }}
            >
                <Container >

                    <Fragment>
                        <Menu.Item  >
                            <Menu.Menu position='right' style={{ width: "80vw" }}>

                                <Dropdown position='right' icon={{ name: 'sidebar', size: 'big' }} pointing className='link item' >
                                    <Dropdown.Menu  >
                                        <Dropdown.Item as={Link} to='/home'>Home</Dropdown.Item>
                                        <Dropdown.Item as={Link} to='/projects'>Projects</Dropdown.Item>
                                        <Dropdown.Item as={Link} onClick={() => { history.push("/"); setTimeout(() => { window.scrollTo({ behavior: "smooth", top: 800 }); }, 100); }}>Services</Dropdown.Item>
                                        <Dropdown.Item as={Link} onClick={() => { history.push("/"); setTimeout(() => { window.scrollTo({ behavior: "smooth", top: 4000 }); }, 100); }}>Contact Us</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                            <Image src={logo} style={{ height: '8vh' }} /> &nbsp;&nbsp;
                        </Menu.Item>
                    </Fragment>
                </Container>
            </Menu>
        </Responsive>
       {/*  <Container style={{marginTop:"13vh"}}> */}
       {childComponent}
{/* </Container> */}
    </Container >
    )
}

export default AppNavbar