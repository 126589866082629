
import React, { useContext, useRef } from "react";


import "./../../styles/tablet-home.css";
import { observer } from "mobx-react";
import { Button } from "semantic-ui-react";
import { useHistory } from 'react-router-dom'
import { RootStoreContext } from "../../store/rootStore";
import DesktopFooter from "../component/footer";

const PurimTechnologiesHomeTablet: React.FC = () => {
        const rootStore = useContext(RootStoreContext);
        let history = useHistory();
        function changeBackground(e: any) {
                e.target.style.background = "orange";
        }

        function change2Background(e: any) {
                e.target.style.background = "orangeRed";
        }


        const contact = useRef();
        return (
                <div>
                        <div className="purimTechnologiesHomeTablet">        <div className="rectangle75"></div>
                                <div className="turnYourdigitalVisionintoReality">{rootStore.commonStore.turnyour}</div>
                                <div className="shareYourVisionAndWeWillShareAProposalOnHowToMakeAReality">{rootStore.commonStore.shareyour}</div>

                                <div className="weBelieveThatTechnologyShouldSupportAndEnhanceYourOrganizationsSuccessOurWideRangeOfBestInClassServicesProvidesCustomizedSolutionsThatFitUniqueItNeeds">We believe that technology should support and enhance your organization’s success. Our wide range of best-in-class services provides customized solutions that fit unique IT needs.</div>
                                {/*     <button className="mainBtnaf8bedaa"   onClick={() =>document.getElementById("scroller")?.scroll(0,2000)}>Contact Us</button> */}
                                <div className="mainBtnaf8bedaa">
                                        <button className="contactUs"
                                                onMouseLeave={change2Background}
                                                onMouseOver={changeBackground}
                                                type="submit" onClick={() => {

                                                        window.scrollTo({ left: 0, top: 3000, behavior: "smooth" })
                                                }}>Contact Us</button>


                                </div>

                                <div className="group174">            <div className="crane0000"></div>
                                        <div className="group165">                <div className="mobileWebAppDevelopmentcf63f143">                    <div className="rectangle421dcd2538"></div>
                                                <div className="maskGroup162b321705"></div>
                                        </div>
                                                <div className="mobileWebAppDevelopment076672d9">Mobile & Web App <br />Development</div>
                                                <div className="builpowerful">{rootStore.commonStore.buildPowerful}</div>
                                                <div className="mainBtnc4cebad5">

                                                        <button className="letsDevelopAnApp181642f3"
                                                                onMouseLeave={change2Background}
                                                                onMouseOver={changeBackground}
                                                                type="submit" onClick={() => {
                                                                        history.push("/contactus");

                                                                        rootStore.commonStore.purpose = "Mobile/Web app Development"
                                                                }}>{rootStore.commonStore.letsDevelop_button}</button>


                                                </div>
                                        </div>
                                </div>

                                <div className="group175">            <div className="businessAnalytics0000"></div>
                                        <div className="group163">                <div className="businessIntelligenceAnalytics195b26d9">                    <div className="rectangle42ff231455"></div>
                                                <div className="maskGroup15"></div>
                                        </div>
                                                <div className="businessIntelligenceAnalytics">{rootStore.commonStore.businessAnalytics}</div>
                                                <div className="wecanHelp">{rootStore.commonStore.weCanHelp}</div>
                                                <div className="dataisoil">{rootStore.commonStore.datanewOil}</div>
                                                <div className="mainBtn7efff55d">

                                                        <button className="getStarted"
                                                                onMouseLeave={change2Background}
                                                                onMouseOver={changeBackground}
                                                                type="submit" onClick={() => {
                                                                        history.push("/contactus");
                                                                        rootStore.commonStore.purpose = "Business Analytics"

                                                                }}>{rootStore.commonStore.getsStarted_button}</button>

                                                </div>
                                        </div>
                                </div>
                                <div className="group172">            <div className="group168">                <div className="mobileWebAppDevelopment">                    <div className="rectangle42"></div>
                                        <div className="maskGroup16"></div>
                                </div>
                                        <div className="mainBtn">

                                                <button className="letsDevelopAnApp"
                                                        onMouseLeave={change2Background}
                                                        onMouseOver={changeBackground}
                                                        type="submit" onClick={() => {
                                                                history.push("/contactus");
                                                                rootStore.commonStore.purpose = "Digital Marketing"

                                                        }}>{rootStore.commonStore.lets_go}</button>


                                        </div>
                                        <div className="digitalMarketing">{rootStore.commonStore.digitalMarket}</div>
                                        <div className="digitalMarketPromotes">{rootStore.commonStore.dmpromotes}</div>
                                </div>
                                        <div className="digitalMarketing0000"></div>
                                </div>
                                <div className="group170">            <div className="group171">                <div className="asset4"></div>
                                        <div className="laptopNew30000"></div>
                                </div>
                                </div>
                                <div className="rectangle124"></div>
                                <div className="group179">            <div className="services6e36be5b">{rootStore.commonStore.services}</div>
                                        <div className="wedeliver">{rootStore.commonStore.weDeliver}</div>
                                        <div className="mainBtn59b2ff04">
                                                <button className="bookAMeeting"
                                                        onMouseLeave={change2Background}
                                                        onMouseOver={changeBackground}
                                                        type="submit" onClick={() => {
                                                                history.push("/contactus");
                                                                rootStore.commonStore.purpose = "Book a Meeting"
                                                        }}>{rootStore.commonStore.bookaMeeting_button}</button>


                                        </div>
                                </div>

                                {/* <div className="rectangle125"></div>
                                <div className="group181">            <div className="whiteWorldVisionLogo"></div>
                                        <div className="whiteCimLogo"></div>
                                        <div className="whiteCmctLogo"></div>
                                </div>
                                <div className="ourClients">{rootStore.commonStore.ourClients}</div>
                                <div className="rectangle127"></div>
                                <div className="rectangle128"></div>
                                <div className="image1"></div> */}
                                <div style={{ marginTop: "3400px" }}>
                                <DesktopFooter />
                        </div>
                        </div>
                       </div>

        );
}





export default observer(PurimTechnologiesHomeTablet);
